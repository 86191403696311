import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  // const handleNavLinkClick = () => {
  //   setIsOpen(false); // Close the menu
  // };

  return (
    <div className="Navbar">
      <div className='nav-logo'>
        <img src="./images/logo.png" alt="Logo" className='logo' />
      </div>

      <nav className={`nav-items ${isOpen && "open"}`}>
        {/* Développement Agricole & Industriel Dropdown */}
        <div className="dropdown">
          <NavLink to="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >
            Développement Agricole & Industriel
          </NavLink>
          <ul className="dropdown-menu">
            <li><NavLink to="/option1" className="dropdown-item" >Répertoire des bénéficiaires</NavLink></li>
            <li><NavLink to="/option2" className="dropdown-item" >Cartographie des acteurs</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Encadrement des promoteurs</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Bonnes pratiques</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Équipe AT – Banque de fichiers media</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Équipe AT SOFRECO</NavLink></li>
          </ul>
        </div>

        {/* Soutien & Encadrement Dropdown */}
        <div className="dropdown">
          <NavLink to="/statistique" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >
            Soutien & Encadrement
          </NavLink>
          <ul className="dropdown-menu">
            <li><NavLink to="/option1" className="dropdown-item" >Production & Rendement</NavLink></li>
            <li><NavLink to="/option2" className="dropdown-item" >Informations sur les marchés</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Intrants et Équipements</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Valorisation des produits – sous-produits</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Réduction des pertes post-récoltes</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Zone Industrielle – Site Aménagé</NavLink></li>
          </ul>
        </div>

        {/* Financement & Soutiens Dropdown (External Link) */}
        <div className="dropdown">
          <NavLink to="https://fier2.fidamali.org/fiches_dynamiques.php" target="_blank" rel="noopener noreferrer" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" >
            Financement & Soutiens
          </NavLink>
          <ul className="dropdown-menu">
            <li><NavLink to="/option1" className="dropdown-item" >Techniques & Technologies</NavLink></li>
            <li><NavLink to="/option2" className="dropdown-item" >Réglementation – cadre juridique</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Modèles – plan affaires & financement</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Fiches micro-projets potentiels</NavLink></li>
            <li><NavLink to="/option3" className="dropdown-item" >Gestion environnementale et sociale</NavLink></li>
          </ul>
        </div>
      </nav>

      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
  );
}
